@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.wallet-adapter-button {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    border-radius: 4px;
}

.wallet-adapter-button-trigger {
    background-color: #512da8;
}

.wallet-adapter-button:not([disabled]):focus-visible {
    outline-color: white;
}

.wallet-adapter-button:not([disabled]):hover {
    background-color: #1a1f2e;
}

.wallet-adapter-button[disabled] {
    background: #404144;
    color: #999;
    cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.wallet-adapter-button-end-icon {
    margin-left: 12px;
}

.wallet-adapter-button-start-icon {
    margin-right: 12px;
}

.wallet-adapter-collapse {
    width: 100%;
}

.wallet-adapter-dropdown {
    position: relative;
    display: inline-block;
}

.wallet-adapter-dropdown-list {
    position: absolute;
    z-index: 99;
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 10px;
    padding: 10px;
    top: 100%;
    right: 0;
    margin: 0;
    list-style: none;
    background: #2c2d30;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.wallet-adapter-dropdown-list-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    height: 37px;
    color: #fff;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
    background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
    align-self: center;
    fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
    transform: rotate(180deg);
    transition: transform ease-in 150ms;
}

.wallet-adapter-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 150ms;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
    opacity: 1;
}

.wallet-adapter-modal-button-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 18px;
    right: 18px;
    padding: 12px;
    cursor: pointer;
    background: #1a1f2e;
    border: none;
    border-radius: 50%;
}

.wallet-adapter-modal-button-close:focus-visible {
    outline-color: white;
}

.wallet-adapter-modal-button-close svg {
    fill: #777;
    transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
    fill: #fff;
}

.wallet-adapter-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.wallet-adapter-modal-container {
    display: flex;
    margin: 3rem;
    min-height: calc(100vh - 6rem); /* 100vh - 2 * margin */
    align-items: center;
    justify-content: center;
}

@media (max-width: 480px) {
    .wallet-adapter-modal-container {
        margin: 1rem;
        min-height: calc(100vh - 2rem); /* 100vh - 2 * margin */
    }
}

.wallet-adapter-modal-wrapper {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1050;
    max-width: 400px;
    border-radius: 10px;
    background: #10141f;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    flex: 1 1;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
    width: 100%;
}

.wallet-adapter-modal-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    padding: 64px 48px 48px 48px;
    text-align: center;
    color: #fff;
}

@media (max-width: 374px) {
    .wallet-adapter-modal-title {
        font-size: 18px;
    }
}

.wallet-adapter-modal-list {
    margin: 0 0 12px 0;
    padding: 0;
    width: 100%;
    list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
    font-weight: 400;
    border-radius: 0;
    font-size: 18px;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon,
.wallet-adapter-modal-list .wallet-adapter-button-end-icon img,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
    width: 28px;
    height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
    margin-left: auto;
    font-size: 14px;
    opacity: .6;
}

.wallet-adapter-modal-list-more {
    cursor: pointer;
    border: none;
    padding: 12px 24px 24px 12px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #fff;
}

.wallet-adapter-modal-list-more svg {
    transition: all 0.1s ease;
    fill: rgba(255, 255, 255, 1);
    margin-left: 0.5rem;
}

.wallet-adapter-modal-list-more-icon-rotate {
    transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 24px 24px;
    box-sizing: border-box;
}

.wallet-adapter-modal-middle-button {
    display: block;
    cursor: pointer;
    margin-top: 48px;
    width: 100%;
    background-color: #512da8;
    padding: 12px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    color: #fff;
}

:root {
	--bgColor: #F7F7F9;
	--color: #1D1E1F;
	--scrollColor: #d6dee1;
	--scrollHoverColor: #a8bbbf;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: 'Red Hat Text', -apple-system, BlinkMacSystemFont, Segoe UI,
		Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
		sans-serif;
	background-color: var(--bgColor);
	color:  var(--color);
}

html {
	font-size: 16px;
}

body {
	/* Please don't remove this */
	overflow-y: auto; 
	overflow-y: overlay;
}

::-webkit-scrollbar {
	width: 20px;
	height: 20px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: var(--scrollColor);
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--scrollHoverColor);
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

/* React-Select component */
.select__menu {
	z-index: 10 !important;
}
.select__control {
	border: 2px solid #ebecf2 !important;
	border-radius: 8px !important;
	height: 54px;
}
.select__control:focus-within {
	border-color: #3811bf !important;
}
.select__value-container {
	padding-left: 16px !important;
}
.select__indicator {
	padding-right: 24px !important;
}
.select__indicators > span {
	display: none;
}

/* Quill component */
.ql-tooltip.ql-hidden {
	display: none;
}

.ql-container {
	min-height: 10rem;
	height: 100%;
	flex: 1 1;
	display: flex;
	flex-direction: column;
}

.ql-editor {
	height: 100%;
	flex: 1 1;
	overflow-y: auto;
	width: 100%;
}

.ql-snow {
	background: white;
}

.quill > .ql-container > .ql-editor.ql-blank&::before {
	font-size: 16px;
	font-family: Red Hat Text, sans-serif;
	font-style: normal;
	font-weight: normal;
	color: #82899a;
}

/* Loader CSS */
.dot-flashing {
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	animation: dot-flashing 1s infinite linear alternate;
	animation-delay: 0.5s;
}
.dot-flashing::before {
	left: -15px;
	animation-delay: 0s;
}
.dot-flashing::before,
.dot-flashing::after {
	border-radius: 5px;
	width: 10px;
	height: 10px;
	animation: dot-flashing 1s infinite alternate;
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
}
.dot-flashing::after {
	left: 15px;
	animation-delay: 1s;
}

@keyframes dot-flashing {
	0% {
		background-color: #e1458d;
	}
	50%,
	100% {
		background-color: #ffc9e2;
	}
}

.fadeIn {
	animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fadeOut {
	animation: fadeOut 0.3s ease-in-out;
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@font-face {
	font-family: 'Red Hat Text';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url(/_next/static/media/red-hat-text-v6-latin-regular.24c15604.woff2)
			format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url(/_next/static/media/red-hat-text-v6-latin-regular.1cbbd5ae.woff)
			format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-text-500 - latin */
@font-face {
	font-family: 'Red Hat Text';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url(/_next/static/media/red-hat-text-v6-latin-500.ec296f60.woff2) format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url(/_next/static/media/red-hat-text-v6-latin-500.2f3adba7.woff) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-display-700 - latin */
@font-face {
	font-family: 'Red Hat Display';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url(/_next/static/media/red-hat-display-v7-latin-700.9a7278ac.woff2)
			format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url(/_next/static/media/red-hat-display-v7-latin-700.1e1f23fa.woff)
			format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
	font-family: 'TeX Gyre Adventor';
	font-style: normal;
	font-weight: normal;
	src: url(/_next/static/media/texgyreadventor-regular-webfont.43517415.woff);
}
@font-face {
	font-family: 'TeX Gyre Adventor';
	font-style: normal;
	font-weight: 700;
	src: url(/_next/static/media/texgyreadventor-bold-webfont.977482ed.woff);
}

.bn-onboard-custom {
	z-index: 10000;
}

#nprogress {
	z-index: 1101;
	position: fixed;
	width: 100%;
	top: 0;
}

#nprogress > .bar {
	height: 4px;
	background-color: #8668fc;
}

.swiper-pagination-bullet {
	border-radius: 50%;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    color: #82899A;
    font-weight: 500;
	padding: 0 8px;
	margin-left: 4px;
	margin-right: 4px;
} 

.swiper-pagination-bullet-active{
    color: #1D1E1F;
    font-weight: 700;
}


.image-uploading {
	position: relative;
	display: inline-block;
  }
  
  .image-uploading img {
	max-width: 98% !important;
	filter: blur(5px);
	opacity: 0.3;
  }
  
  .image-uploading&::before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 30px;
	height: 30px;
	margin-top: -15px;
	margin-left: -15px;
	border-radius: 50%;
	border: 3px solid #ccc;
	border-top-color: #1e986c;
	z-index: 1;
	animation: spinner 0.6s linear infinite;
  }
  
  @keyframes spinner {
	to {
	  transform: rotate(360deg);
	}
  }

  wcm-modal{
	display: none;
  }
