:root {
	--bgColor: #F7F7F9;
	--color: #1D1E1F;
	--scrollColor: #d6dee1;
	--scrollHoverColor: #a8bbbf;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family: 'Red Hat Text', -apple-system, BlinkMacSystemFont, Segoe UI,
		Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
		sans-serif;
	background-color: var(--bgColor);
	color:  var(--color);
}

html {
	font-size: 16px;
}

body {
	/* Please don't remove this */
	overflow-y: auto; 
	overflow-y: overlay;
}

::-webkit-scrollbar {
	width: 20px;
	height: 20px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: var(--scrollColor);
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--scrollHoverColor);
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

/* React-Select component */
.select__menu {
	z-index: 10 !important;
}
.select__control {
	border: 2px solid #ebecf2 !important;
	border-radius: 8px !important;
	height: 54px;
}
.select__control:focus-within {
	border-color: #3811bf !important;
}
.select__value-container {
	padding-left: 16px !important;
}
.select__indicator {
	padding-right: 24px !important;
}
.select__indicators > span {
	display: none;
}

/* Quill component */
.ql-tooltip.ql-hidden {
	display: none;
}

.ql-container {
	min-height: 10rem;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.ql-editor {
	height: 100%;
	flex: 1;
	overflow-y: auto;
	width: 100%;
}

.ql-snow {
	background: white;
}

.quill > .ql-container > .ql-editor.ql-blank&::before {
	font-size: 16px;
	font-family: Red Hat Text, sans-serif;
	font-style: normal;
	font-weight: normal;
	color: #82899a;
}

/* Loader CSS */
.dot-flashing {
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	animation: dot-flashing 1s infinite linear alternate;
	animation-delay: 0.5s;
}
.dot-flashing::before {
	left: -15px;
	animation-delay: 0s;
}
.dot-flashing::before,
.dot-flashing::after {
	border-radius: 5px;
	width: 10px;
	height: 10px;
	animation: dot-flashing 1s infinite alternate;
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
}
.dot-flashing::after {
	left: 15px;
	animation-delay: 1s;
}

@keyframes dot-flashing {
	0% {
		background-color: #e1458d;
	}
	50%,
	100% {
		background-color: #ffc9e2;
	}
}

.fadeIn {
	animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fadeOut {
	animation: fadeOut 0.3s ease-in-out;
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@font-face {
	font-family: 'Red Hat Text';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('../public/fonts/red-hat-text-v6-latin-regular.woff2')
			format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('../public/fonts/red-hat-text-v6-latin-regular.woff')
			format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-text-500 - latin */
@font-face {
	font-family: 'Red Hat Text';
	font-style: normal;
	font-weight: 500;
	src: local(''),
		url('../public/fonts/red-hat-text-v6-latin-500.woff2') format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('../public/fonts/red-hat-text-v6-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* red-hat-display-700 - latin */
@font-face {
	font-family: 'Red Hat Display';
	font-style: normal;
	font-weight: 700;
	src: local(''),
		url('../public/fonts/red-hat-display-v7-latin-700.woff2')
			format('woff2'),
		/* Chrome 26+, Opera 23+, Firefox 39+ */
			url('../public/fonts/red-hat-display-v7-latin-700.woff')
			format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
	font-family: 'TeX Gyre Adventor';
	font-style: normal;
	font-weight: normal;
	src: url('../public/fonts/texgyreadventor-regular-webfont.woff');
}
@font-face {
	font-family: 'TeX Gyre Adventor';
	font-style: normal;
	font-weight: 700;
	src: url('../public/fonts/texgyreadventor-bold-webfont.woff');
}

.bn-onboard-custom {
	z-index: 10000;
}

#nprogress {
	z-index: 1101;
	position: fixed;
	width: 100%;
	top: 0;
}

#nprogress > .bar {
	height: 4px;
	background-color: #8668fc;
}

.swiper-pagination-bullet {
	border-radius: 50%;
    cursor: pointer;
    user-select: none;
    color: #82899A;
    font-weight: 500;
	padding: 0 8px;
	margin-left: 4px;
	margin-right: 4px;
} 

.swiper-pagination-bullet-active{
    color: #1D1E1F;
    font-weight: 700;
}


.image-uploading {
	position: relative;
	display: inline-block;
  }
  
  .image-uploading img {
	max-width: 98% !important;
	filter: blur(5px);
	opacity: 0.3;
  }
  
  .image-uploading&::before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 30px;
	height: 30px;
	margin-top: -15px;
	margin-left: -15px;
	border-radius: 50%;
	border: 3px solid #ccc;
	border-top-color: #1e986c;
	z-index: 1;
	animation: spinner 0.6s linear infinite;
  }
  
  @keyframes spinner {
	to {
	  transform: rotate(360deg);
	}
  }

  wcm-modal{
	display: none;
  }